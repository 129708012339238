import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-c-suiteondeck',
  templateUrl: './c-suiteondeck.component.html',
  styleUrls: ['./c-suiteondeck.component.scss']
})
export class CSuiteondeckComponent implements OnInit {
cSuiteOnDeckDatas: any;
cSuiteOnDeckloaded : boolean;
firstString: string;
envSiteName:any;

  constructor(private httpclientService: HttpclientService,public routeUrl: Router,private titleService: Title,private metaTagService: Meta) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.cSuiteOnDeckData();

  }
  // redirect(getUrl:any,getId:any){
  //   this.firstString = getUrl.substring(0,5);
  //   if(this.firstString ==="https"){
  //     window.open(getUrl, "_blank");
  //   }else{
  //     this.routeUrl.navigate(['/companies/all-c-suiteondeck/'+getUrl+"/"+getId]);
  //   }
  // }
  cSuiteOnDeckData() : void {
    this.cSuiteOnDeckloaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalCompanies/GetGlobalCompanyTOP4CSuiteOnDeck?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.cSuiteOnDeckDatas =items;
        this.cSuiteOnDeckloaded = true;
        //console.log("C-Suite On DeckDatas", this.cSuiteOnDeckDatas);

      //seo code for tags
      this.titleService.setTitle(this.cSuiteOnDeckDatas.InterviewTitle);
      this.metaTagService.updateTag(
        { name: 'og:description', content: this.cSuiteOnDeckDatas.InterviewDetails}
      );
      this.metaTagService.updateTag(
        { name: 'og:title', content: this.cSuiteOnDeckDatas.InterviewTitle}
      );
      this.metaTagService.updateTag(
        { name: 'og:image', content: this.cSuiteOnDeckDatas.InterviewImage}
      );
      this.metaTagService.updateTag(
        { name: 'twitter:description', content: this.cSuiteOnDeckDatas.InterviewDetails}
      );
      this.metaTagService.updateTag(
        { name: 'twitter:title', content: this.cSuiteOnDeckDatas.InterviewTitle}
      );
      this.metaTagService.updateTag(
        { name: 'twitter:image', content: this.cSuiteOnDeckDatas.InterviewImage}
      );
      //seo code for tags end

      }
    );
  }
}
