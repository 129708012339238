import { Component, OnInit, Renderer2, OnChanges, SimpleChanges,HostListener } from '@angular/core';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../src/environments/environment';
import { Title, Meta,MetaDefinition, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {

  isOpen = false;
  isOverlay = false;
  title : string;
  newsletterOpen:boolean=false;
  searchData:string;
  iData: any;
  loaded: boolean;
  iDataMeta: any;
  loadedMeta: boolean;
  logo: any;
  envSiteName:any;
  preSite: any;
  pageType:any;
  MetaTagLoadedSerch: boolean;
  MetaTagDataSerch: any;

  open(): void {
    this.isOpen = true;
  }

  constructor(private router:Router,private renderer: Renderer2,private metaTagService: Meta,private httpclientService: HttpclientService,private activatedRoute: ActivatedRoute) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {

    this.headerTitle();
    this.logo = '/images/'+window.location.host + '.png';
    this.LogoData();
    let prevScrollpos = window.pageYOffset;
    // this.metaTagService.addTags([
    //   { name: 'robots', content: 'index, follow' }
    //   // { charset: 'UTF-8' }
    // ]);
    window.onscroll = function() {
    let currentScrollPos = window.pageYOffset;
      if( window.innerWidth >= 991 ){
        if (prevScrollpos > currentScrollPos) {
          document.getElementById('navbar').style.top = '0';
        } else {
          document.getElementById('navbar').style.top = '-150px';
        }
      
        prevScrollpos = currentScrollPos;

        if(prevScrollpos==0){
          document.getElementById('navbar').style.boxShadow = 'none';
        }else{
          document.getElementById('navbar').style.boxShadow= '0px 0px 15px var(--Gray3)';
        }
      }
    }

  }
  // @HostListener('click') 
  // navclick(){
  //   window.location.reload();
  // }

  reload(){
    this.ngOnInit();
  }

  // @HostListener('ng-click') 
  // refresh(){
  //   window.location.reload();
  // }

  //@HostListener('click') 
  overlay(): void{
    this.isOverlay = !this.isOverlay;
    if(this.isOverlay == true){
      document.getElementById("overlay").style.display = "block";
    }
    else if(this.isOverlay == false){
      document.getElementById("overlay").style.display = "none";
    }
     //window.location.reload();
  }
  // ngOnChanges(changes: SimpleChanges){
  //   console.log("on change call");
  //   if(this.isOpen== true)
  //   this.renderer.listen('window', 'click',(e:Event)=>{this.isOpen=false });
  // }
  

  newsletterPop(){
    this.newsletterOpen=true;
  }

  searchKey(searchData:string){
    if(searchData!="")
    this.router.navigate(['/global-search', { id: searchData}]);
    searchData=" ";
    this.isOpen = closed;
    this.searchData = '';
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
        (  items: any) => {
          this.iData = items[0];
          this.preSite = this.iData.PreSite;
          this.loaded = true;
        });
  }
  headerTitle(): void {
    this.activatedRoute.snapshot.params;
    this.loadedMeta = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Home')
      .subscribe(
        (  items: any) => {
          this.iDataMeta = items[0];
          this.loadedMeta = true;
        });
  }

}
