import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');


@Component({
  selector: 'app-upcoming-conferences',
  templateUrl: './upcoming-conferences.component.html',
  styleUrls: ['./upcoming-conferences.component.scss']
})
export class UpcomingConferencesComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 9;
  UpcomingConferenceDatas:any;  
  ConferenceLoaded:boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedConferences:boolean;
  MetaTagDataConferences:any;
  constructor(private state: TransferState,private metaTagService: Meta,private titleService: Title,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.UpcomingConferences();
    this.AllMetaTagUpcomingConferences();
  }

  UpcomingConferences(): void {
  //  if(this.ConferenceLoaded!=true){
      this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.ConferenceLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllUpCommingConferences?siteName='+this.envSiteName+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
          'in the browser' : 'on the server';
          this.UpcomingConferenceDatas = items;
          this.count=this.UpcomingConferenceDatas[0].TotalDataCount;
          this.ConferenceLoaded = true;
          this.state.set(STATE_KEY_ITEMS, <any> items);
           //console.log("Upcoming AllConferences",this.UpcomingConferenceDatas)

           window.scrollTo(0,0);
        });
    //  }
  }

  resetUsers(): void {
    this.UpcomingConferenceDatas = null;
    this.ConferenceLoaded = true;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.UpcomingConferences();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
AllMetaTagUpcomingConferences(): void {
  if(this.MetaTagLoadedConferences!=true){
  this.MetaTagLoadedConferences = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName=Upcoming Conferences')
  .subscribe(
    items => {
      this.MetaTagDataConferences = items[0];
      this.MetaTagLoadedConferences=true;
      //console.log('this.MetaTagDataNews',this.MetaTagDataConferences);
      

        //seo code for tags
        this.canonical.CreateCanonicalLink();

        this.titleService.setTitle(this.MetaTagDataConferences.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'},
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataConferences.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataConferences.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagDataConferences.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataConferences.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataConferences.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDataConferences.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDataConferences.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDataConferences.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDataConferences.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDataConferences.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataConferences.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataConferences.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagDataConferences.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataConferences.OgImageURL}
        );
        //seo code for tags end
      });
    }
}

}
