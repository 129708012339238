import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
// import { environment } from '../../../../../src/environments/environment';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';

@Component({
  selector: 'app-globel-resources',
  templateUrl: './globel-resources.component.html',
  styleUrls: ['./globel-resources.component.scss']
})
export class GlobelResourcesComponent implements OnInit {

  href:any;
  lastString:string;
  envSiteName:any;
  ResourceMetaTagLoaded: boolean;
  ResourceMetaTagData: any;
  GlobalResourcesTopAD:SafeResourceUrl;
  GlobalArticlesTopAD:SafeResourceUrl;
  GlobalVideosTopAD:SafeResourceUrl;
  GlobalWhitepapersTopAD:SafeResourceUrl;
  GlobalInfographicsTopAD:SafeResourceUrl;

  resoAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalResourcesTopAD.html'
  artiAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalArticlesTopAD.html'
  videAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalVideosTopAD.html'
  whitAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalWhitepapersTopAD.html'
  infoAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalInfographicsTopAD.html'

  reso:String= "https://"+window.location.hostname+"/resources";
  arti:String= "https://"+window.location.hostname+"/resources/articles";
  vide:string = "https://"+window.location.hostname+"/resources/videos";
  whit:String= "https://"+window.location.hostname+"/resources/whitepapers";
  info:string = "https://"+window.location.hostname+"/resources/infographics";
  currentpageeurl:string=window.location.href;

  constructor(private httpclientService: HttpclientService,private router:Router,private sanitizer:DomSanitizer,private titleService: Title,private metaTagService: Meta,private canonical:CanonicalService){
    this.envSiteName=environment.siteName; }

  ngOnInit(): void {
    this.GlobalResourcesTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.resoAD);
    this.GlobalArticlesTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.artiAD);
    this.GlobalVideosTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.videAD);
    this.GlobalWhitepapersTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.whitAD);
    this.GlobalInfographicsTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.infoAD);
    this.ResourceAllMetaData();
    //this.ResourcesAD();
  }
  ngOnChanges(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1)
    //console.log("this.lastString",this.lastString);
  }

  ResourcesAD(): void{

    if(this.arti == window.location.href){
       document.getElementById('arti').classList.add('displayblock');
    }else if(this.vide == window.location.href){
      document.getElementById('vide').classList.add('displayblock'); 
    }else if(this.whit == window.location.href){
      document.getElementById('whit').classList.add('displayblock'); 
    }else if(this.info == window.location.href){
      document.getElementById('info').classList.add('displayblock'); 
    }else{
      document.getElementById('reso').classList.add('displayblock'); 
     }
  }

  ResourceAllMetaData(): void {
    if(this.ResourceMetaTagLoaded!=true){
    this.ResourceMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Resources')
    .subscribe(
      items => {
        this.ResourceMetaTagData = items[0];
        this.ResourceMetaTagLoaded=true;
        //console.log('this.ResourceMetaTagData',this.ResourceMetaTagData);
          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.ResourceMetaTagData.PageTitle);

          this.metaTagService.updateTag(
            {name:'robots',content:'index,follow'}
          );

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.ResourceMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.ResourceMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:keywords', content: this.ResourceMetaTagData.OgKeyWords}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.ResourceMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.ResourceMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'description', content: this.ResourceMetaTagData.OgDescription}
            );
  
            this.metaTagService.updateTag(
              { name: 'title', content: this.ResourceMetaTagData.PageTitle}
            );
  
            this.metaTagService.updateTag(
              { name: 'keywords', content: this.ResourceMetaTagData.OgKeyWords}
            );
  
            this.metaTagService.updateTag(
              { name: 'image', content: this.ResourceMetaTagData.OgImageURL}
            );
  
            this.metaTagService.updateTag(
              { name: 'image:alt', content: this.ResourceMetaTagData.PageTitle}
            );

          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.ResourceMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.ResourceMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:keywords', content: this.ResourceMetaTagData.OgKeyWords}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.ResourceMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
