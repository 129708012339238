import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;
  resourcesVideoDatas: any;
  resourceVideoLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedVideo: boolean;
  MetaTagDataVideo: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }
  

  ngOnInit(): void {
    this.resourcesVideoData();
    this.AllMetaTagVideo();
  }

  resourcesVideoData(): void
  {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.resourceVideoLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetAllGlobalReourcesVideos?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
        this.resourcesVideoDatas = items;
        this.count = this.resourcesVideoDatas[0].TotalDataCount;
        this.resourceVideoLoaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("resources Video Datas", this.resourcesVideoDatas);
        window.scrollTo(0,0);
      });
  }

  resetUsers(): void {
    this.resourcesVideoDatas = null;
    this.resourceVideoLoaded = true;
    // this.onActivate();
  }

  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.resourcesVideoData();
    // this.onActivate();
  }

//   onActivate() {
//     this.scrollToTop = window.setInterval(() => {
//         this.pos = window.pageYOffset;
//         console.log("pos value",this.pos)
//         if (this.pos > 0) {
//             window.scrollTo(0, this.pos - 40); // how far to scroll on each step
//         } 
//         else {
//             window.clearInterval(this.scrollToTop);  
//         }
//     }, 2);
// }
AllMetaTagVideo(): void {
  if(this.MetaTagLoadedVideo!=true){
  this.MetaTagLoadedVideo = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Videos')
  .subscribe(
    items => {
      this.MetaTagDataVideo = items[0];
      this.MetaTagLoadedVideo=true;
      //console.log('this.MetaTagDataVideo',this.MetaTagDataVideo);


        //seo code for tags
        this.canonical.CreateCanonicalLink();

        this.titleService.setTitle(this.MetaTagDataVideo.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'}
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataVideo.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataVideo.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagDataVideo.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataVideo.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataVideo.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDataVideo.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDataVideo.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDataVideo.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDataVideo.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDataVideo.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataVideo.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataVideo.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagDataVideo.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataVideo.OgImageURL}
        );
        //seo code for tags end
      });
    }
}
}
