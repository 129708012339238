import { Component, HostListener, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import {CanonicalService} from '../../../../canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-trending-news',
  templateUrl: './trending-news.component.html',
  styleUrls: ['./trending-news.component.scss']
})
export class TrendingNewsComponent implements OnInit {

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;

  trendingNewsDatas: any;
  trendingNewsLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedTrending: boolean;
  MetaTagDataTrending: any;

  fnurl:String= "https://"+window.location.hostname+"/news/featured-news";
  tnurl:string = "https://"+window.location.hostname+"/news/trending-news";
  currentpageeurl:string=window.location.href;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.trendingNewsData();
    this.AllMetaTagTrending();
  }
  trendingNewsData(): void{
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.trendingNewsLoaded =false;
    this,this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetAllTrendingNews?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
          //console.log(`getUsers : Running ${platform} with appId=${this.appId}`);
          
        this.trendingNewsDatas = items;
        this.count=this.trendingNewsDatas[0].TotalDataCount;
        this.trendingNewsLoaded = true;
        //console.log("trendingNews Data",this.trendingNewsDatas);
        this.state.set(STATE_KEY_ITEMS, <any> items);
        window.scrollTo(0,0);
      }
    );

  }

  resetUsers(): void {
    this.trendingNewsDatas = null;
    this.trendingNewsLoaded = true;
  }

  // on click of pagination tabs
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.trendingNewsData();
    this.onActivate();
  }

  // for scroll of to top on page change
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}


AllMetaTagTrending(): void {
  if(this.MetaTagLoadedTrending!=true){
  this.MetaTagLoadedTrending = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Trending News')
  .subscribe(
    items => {
      this.MetaTagDataTrending = items[0];
      this.MetaTagLoadedTrending=true;
      //console.log('this.MetaTagDataTrending',this.MetaTagDataTrending);


        //seo code for tags
        this.canonical.CreateCanonicalLink();
        
        this.titleService.setTitle(this.MetaTagDataTrending.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'}
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataTrending.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataTrending.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keyword', content: this.MetaTagDataTrending.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataTrending.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataTrending.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDataTrending.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDataTrending.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDataTrending.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDataTrending.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDataTrending.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataTrending.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataTrending.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keyword', content: this.MetaTagDataTrending.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataTrending.OgImageURL}
        );
        //seo code for tags end
      });
    }
}

}
