import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-webiners',
  templateUrl: './all-webiners.component.html',
  styleUrls: ['./all-webiners.component.scss']
})
export class AllWebinersComponent implements OnInit {

  href:any;
  lastString:string;

  constructor(private router:Router) { }

  ngOnInit(): void {
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1)
  }

}
