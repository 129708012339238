import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResourcesComponent } from './resources.component';
import { ResourcesRoutingModule } from './resources-routing.module';
import { GlobelResourcesComponent } from './globel-resources/globel-resources.component';
import { ArticlesComponent } from './globel-resources/articles/articles.component';
import { ArticlesCategoryComponent } from './globel-resources/articles/articles-category-page/articles-category-page.component';
import { VideoComponent } from './globel-resources/video/video.component';
import { WhitepaperComponent } from './globel-resources/whitepaper/whitepaper.component';
import { InfographicComponent } from './globel-resources/infographic/infographic.component';
import { WebinerComponent } from './globel-resources/webiner/webiner.component';
import { ResourcesSpotlightComponent } from './resources-spotlight/resources-spotlight.component';
import { ResourcesEventComponent } from './resources-event/resources-event.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { GlobalGuestAuthorsComponent } from './global-guest-authors/global-guest-authors.component';
// import { ResourcesGuestAuthorsComponent } from './global-guest-authors/resources-guest-authors/resources-guest-authors.component';
import { ResourcesGuestAuthorsComponent } from './single-guest-authors-profile/resources-guest-authors/resources-guest-authors.component';
import { SpotlightGuestAuthorsComponent } from './global-guest-authors/spotlight-guest-authors/spotlight-guest-authors.component';
import { SingleGuestAuthorsProfileComponent } from './single-guest-authors-profile/single-guest-authors-profile.component';
import { VideoSinglePageComponent } from './globel-resources/video-single-page/video-single-page.component';
import { OtherVideosComponent } from './globel-resources/video-single-page/other-videos/other-videos.component';
import { SigleGuestAuthorNewsComponent } from './single-guest-authors-profile/sigle-guest-author-news/sigle-guest-author-news.component';
import { SigleGuestOtherAuthorComponent } from './single-guest-authors-profile/sigle-guest-other-author/sigle-guest-other-author.component';
import { SquareAdVideosComponent} from './globel-resources/video-single-page/square-ad-videos/square-ad-videos.component';
import { ArticleSinglePageComponent } from './globel-resources/article-single-page/article-single-page.component';
import { OtherArticlesComponent } from './globel-resources/article-single-page/other-articles/other-articles.component';
import { SigleGuestAuthorsRelatedArticlesComponent } from './global-guest-authors/sigle-guest-authors-related-articles/sigle-guest-authors-related-articles.component';
import { SingleWhitepaperPageComponent } from './single-whitepaper-page/single-whitepaper-page.component';
import { SingleWhitepaperSpotlightComponent } from './single-whitepaper-page/single-whitepaper-spotlight/single-whitepaper-spotlight.component';
import { SingleWhitepaperEventsComponent } from './single-whitepaper-page/single-whitepaper-events/single-whitepaper-events.component';
import { SingleWhitepaperOthersComponent } from './single-whitepaper-page/single-whitepaper-others/single-whitepaper-others.component';
import { SingleWhitepaperDownloadformComponent } from './single-whitepaper-page/single-whitepaper-downloadform/single-whitepaper-downloadform.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SingleInfographicsPageComponent } from './single-infographics-page/single-infographics-page.component';
import { SingleInfographicsDownloadComponent } from './single-infographics-page/single-infographics-download/single-infographics-download.component';
import { SingleInfographicsEventsComponent } from './single-infographics-page/single-infographics-events/single-infographics-events.component';
import { SingleInfographicsOthersComponent } from './single-infographics-page/single-infographics-others/single-infographics-others.component';
import { SingleInfographicsSpotlightComponent } from './single-infographics-page/single-infographics-spotlight/single-infographics-spotlight.component';
import { GuestOtherAuthersComponent } from './single-guest-authors-profile/guest-other-authers/guest-other-authers.component';
import { SingleGuestAuthorSpotlightComponent } from './single-guest-authors-profile/single-guest-author-spotlight/single-guest-author-spotlight.component';

@NgModule({
  declarations: [
    ResourcesComponent,
    GlobelResourcesComponent,
    ArticlesComponent,
    ArticlesCategoryComponent,
    VideoComponent,
    WhitepaperComponent,
    InfographicComponent,
    WebinerComponent,
    ResourcesSpotlightComponent,
    ResourcesEventComponent,
    GlobalGuestAuthorsComponent,
    ResourcesGuestAuthorsComponent,
    SpotlightGuestAuthorsComponent,
    SingleGuestAuthorsProfileComponent,
    VideoSinglePageComponent,
    OtherVideosComponent,
    SigleGuestAuthorNewsComponent,
    SigleGuestOtherAuthorComponent,
    SquareAdVideosComponent,
    ArticleSinglePageComponent,
    OtherArticlesComponent,
    SigleGuestAuthorsRelatedArticlesComponent,
    SingleWhitepaperPageComponent,
    SingleWhitepaperSpotlightComponent,
    SingleWhitepaperEventsComponent,
    SingleWhitepaperOthersComponent,
    SingleWhitepaperDownloadformComponent,
    SingleInfographicsPageComponent,
    SingleInfographicsDownloadComponent,
    SingleInfographicsEventsComponent,
    SingleInfographicsOthersComponent,
    SingleInfographicsSpotlightComponent,
    GuestOtherAuthersComponent,
    SingleGuestAuthorSpotlightComponent
  ],
  imports: [
    CommonModule,
    ResourcesRoutingModule,
    NgxPaginationModule,
    ContentLoaderModule,
    ReactiveFormsModule
  ],
  exports: [
    ResourcesComponent
  ],
  providers: [
  ],
})
export class ResourcesModule { }
