import { Component, OnInit, ViewChild,Output,EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormBuilder,  Validators  } from '@angular/forms';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { HttpClient  } from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-newsletter-pop',
  templateUrl: './newsletter-pop.component.html',
  styleUrls: ['./newsletter-pop.component.scss'], 
})

export class NewsletterPopComponent implements OnInit {

  @ViewChild("closebutton") closebutton;
  newsLetterForm: FormGroup;
  submitted = false;
  ipAddress = '';
  newsLetterSubscribe:any;
  envSiteName:any;
  private isConsented: boolean=false;
  isShowDivIf:boolean=false;
  name:string="cookieconsent_status";

  @Output() cookies:EventEmitter<boolean>=new EventEmitter();
  constructor(private route: Router,private formBuilder: FormBuilder, private httpclientService: HttpclientService, private http:HttpClient,private cookieService : CookieService) {
    this.createnewsLetterForm();
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.getIPAddress();
    let acceptcheck = this.cookieService.check('cookieconsent_status');
    //let declinecheck = this.cookieService.check('decline');
   
    if(this.cookieService.check('cookieconsent_status')){
      this.isShowDivIf = !this.isShowDivIf;
      this.cookies.emit(this.isShowDivIf);
    }
    else
    {
      this.isShowDivIf = this.isShowDivIf;
      this.cookies.emit(this.isShowDivIf);
    }
  }

    // convenience getter for easy access to form fields
    get f() { return this.newsLetterForm.controls; }

  /*
  cookieValue:any;
  constructor(private cookie: CookieService) { 
    
  }

  click(){
  this.cookieValue = "Email=ankit@nathanark.com";
  this.cookie.set("MyLPCookies",this.cookieValue);
  }*/

  NewsLetterSubscribe() : void 
  {

  }
  
  onSubmit() {
    this.submitted = true;
    if (this.newsLetterForm.invalid) {
        return;
    }    
    if (this.newsLetterForm.valid) {
      let cookieHide = document.getElementById('cookieId');
      cookieHide.style.display = "none";
     }
    var formData: any = new FormData();
    formData.email = this.newsLetterForm.get('email').value;     
    formData.acceptTerms=this.newsLetterForm.get('newsAcceptTerms').value;
    formData.Ip = this.ipAddress;
    formData.siteName=this.envSiteName;

  
    this.newsLetterSubscribe = false;    
    this.httpclientService.postFormData('https://newapi.nathanark.com/api/AllForms/NewsLetterSignUp',  this.getFormUrlEncodedData(formData))
      .subscribe(
        items => {
          this.closebutton.nativeElement.click();
          this.newsLetterSubscribe = items;          
          if(this.newsLetterSubscribe==="Created"){
            this.closebutton.nativeElement.click();
            this.route.navigateByUrl('/thank-you');
            // setTimeout(() => {
            //   // this.isShowDivIf = !this.isShowDivIf;
            //   window.location.reload();
            // }, 1000);
          }
        });


     ////////////////////cookies///////////////
     this.isShowDivIf = !this.isShowDivIf;
     const dateNow = new Date().getFullYear()+2;
     this.setCookie(this.name, "accept", 1);

    //  setTimeout(() => {
    //   // this.isShowDivIf = !this.isShowDivIf;
    //   window.location.reload();
    // }, 1000);
  }
 
  setCookie(cName, cValue, expDays) {
    let date = new Date();
   //date.setTime(date.getTime() + (expDays * 60 * 60 * 60 * 1000));
   date.setFullYear(date.getFullYear() + 2);
   const expires = "expires=" + date.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";

   if(new Date()>=date){
    this.isShowDivIf = this.isShowDivIf;
   }
  }

  getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  getFormUrlEncodedData(toConvert) {
		const formBody = [];
		for (const property in toConvert) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(toConvert[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		return formBody.join('&');
  }


  createnewsLetterForm(){
    this.newsLetterForm = this.formBuilder.group({               
      email: ['', [Validators.required, Validators.email]],
      newsAcceptTerms: [false, Validators.requiredTrue]   
    });
  }
 
  onReset() {
    this.submitted = false;
    this.newsLetterForm.reset();
  }

}
 