<!-- <div class="row d-none d-md-flex" *ngIf="ConferenceLoaded">
    <div class="col-md-4 pb-5" *ngFor="let conferenceData of PastConferenceDatas">
        <div>
            <a href="#" target="_blank" class="text-decoration-none">
                <img class="img-fluid pb-2 image-hight-news " [src]="conferenceData.ImageUrl" alt="news image"></a>

            <a href="#" target="_blank" class="text-decoration-none">
                <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="conferenceData.Name">
                </h4>
            </a>
            <p class="para-2 mb-1"> {{conferenceData.DateWithStartTime}} | {{conferenceData.Country | firstWord}}

            </p>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="conferenceData.Details"></p>
            <a class="para-2 para-2-c-2 " href="">Read More</a>
        </div>
    </div>
</div>  -->

<div class="row" *ngIf='pastConferenceLoaded == false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex WrapArt" *ngIf="pastConferenceLoaded">
    <div class="col-md-4 pb-5" *ngFor="
    let pastconferenceData of PastConferenceDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
        let i = index
    " [class.active]="i == currentIndex">
         <!-- <div class="cursor-p" routerLink="{{pastconferenceData.RouteURL}}/{{pastconferenceData.EventID}}">  -->
            <!-- <div class="cursor-p" routerLink="{{pastconferenceData.URL}}"> -->
                <!-- <a  routerLink="{{pastconferenceData.RouteURL}}/{{pastconferenceData.EventID}}" target="_blank" > -->
        <a  href='https://{{envSiteName}}/{{pastconferenceData.EventDifferentType | lowercase }}/{{pastconferenceData.RouteURL}}' target="_blank" >
            <img class="img-fluid pb-2 image-hight-news " [src]="pastconferenceData.ImageUrl" alt="conference image">
            <h4 class="text-height-3 card-header-height-3 text-uppercase MainTitle pastTitle " [innerHTML]="pastconferenceData.Name">
            </h4>
        </a>
            <p class="para-2 mb-1 sponDate"> {{pastconferenceData.DateWithStartTime}} <span *ngIf='pastconferenceData.DateWithStartTime!="" && pastconferenceData.Country!=""'>|</span> {{pastconferenceData.Country | firstWord}} 
                <span *ngIf='pastconferenceData.IsSponcered==true'>| <font>Sponsored</font></span>
            </p>
            <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="pastconferenceData.Details"></p>
            <!-- <a class="para-2 para-2-c-2 " href=" ">Read More</a> -->

            <!-- <a class="para-2 para-2-c-2" target="_blank" routerLink="{{pastconferenceData.RouteURL}}/{{pastconferenceData.EventID}}">Read More</a>  -->
            <a class="para-2 para-2-c-2 Read_More" target="_blank" href='https://{{envSiteName}}/{{pastconferenceData.EventDifferentType | lowercase }}/{{pastconferenceData.RouteURL}}'>Read More</a> 
        <!-- </div> -->
    </div>
</div>

<div class="row">
    <div class="col-12 p-0 text-center pgntn mt-md-0" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>