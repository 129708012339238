import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {

  constructor(@Inject(DOCUMENT) private dom) { }

  CreateCanonicalLink(url?:string){
    let canURL=url==undefined? this.dom.URL:url;
    let link: HTMLLinkElement= this.dom.createElement('link');
    link.setAttribute('rel','canonical');

   this.dom.head.appendChild(link);
    link.setAttribute('href',canURL);
    



let existLink = this.dom.head.querySelectorAll('[rel="canonical"]')[0]; // checking if 'canonical' exists

existLink ? this.dom.head.replaceChild(link, existLink) : this.dom.head.appendChild(link); // add or update accordingly

link.setAttribute('href', this.dom.URL); // set  href attribute

  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }
}
