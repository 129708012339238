<div class="row">
    <div class="text-center add-center" >
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalCompanyTopAD"></iframe></div>
    <div class="col-md-12">
        <router-outlet>
        </router-outlet>
        
    </div>
   
</div>
