<h1 class="text-uppercase mb-0">Events</h1>
<div class="row">
    <div class="col-md-6">
        <h3>Conferences</h3>
        <ul class="nav nav-tabs w-100 " id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-2 pr-2 text-uppercase text-center" id="home-tab" data-toggle="tab" href="#conferances-tab1" role="tab" aria-controls="home" aria-selected="true">upcoming conferences</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-md-3 pr-md-4 text-uppercase text-center" id="profile-tab" data-toggle="tab" href="#conferances-tab2" role="tab" aria-controls="profile" aria-selected="false">past conferences</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="conferances-tab1" role="tabpanel" aria-labelledby="home-tab">
                <div class="row d-md-flex" *ngIf="ConferenceLoaded">
                  <div class="fix-min-height-events WrapArtT"> 
                    <div class="col-md-12 pb-5" *ngFor="let AllUpComingConferences of ConferenceDatas">
                        <div>
                            <a target="_blank" href="upcoming-conferences/{{AllUpComingConferences.RouteURL}}" class="text-decoration-none">
                                <img class="img-fluid pb-2 image-hight-events" [src]="AllUpComingConferences.ImageUrl" alt="conference image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle" [innerHTML]="AllUpComingConferences.Name"></h4>
                            </a>
                            <p class="para-2 mb-1 sponDate"> {{AllUpComingConferences.DateWithStartTime}} <span *ngIf='AllUpComingConferences.DateWithStartTime!="" && AllUpComingConferences.Country!=""'>|</span> {{AllUpComingConferences.Country}}
                                <span *ngIf='AllUpComingConferences.IsSponcered==true'>| <font>Sponsored</font></span>
                            </p>
                            <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="AllUpComingConferences.Details"></p>
                            <!-- <a class="para-2 para-2-c-2 " href="{{AllUpComingConferences.URL}}" target="_blank">Register Now</a> -->
                            <a class="para-2 para-2-c-2 Read_More" target="_blank" href="upcoming-conferences/{{AllUpComingConferences.RouteURL}} ">Register Now</a>

                        </div>
                    </div>
                </div>
                <a routerLink="/events/upcoming-conferences" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color btnDm btnM">more upcoming conferences</a>
            </div>
        </div>
            <div class="tab-pane fade pt-3" id="conferances-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <!-- <div class="tab-pane fade show active pt-3" id="conferances-tab2" role="tabpanel" aria-labelledby="profile-tab"> -->
                <div class="row d-md-flex " *ngIf="EventLoaded">
                  <div class="fix-min-height-events WrapArtT">
                    <div class="col-md-12 pb-5" *ngFor="let AllEvents of EventNewsDatas">
                        <div>
                            <a target="_blank"  href="past-conferences/{{AllEvents.RouteURL}}" class="text-decoration-none">
                                <img class="img-fluid pb-2 image-hight-events" [src]="AllEvents.ImageUrl" alt="conference image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle" [innerHTML]="AllEvents.Name">
                                </h4>
                            </a>
                            <p class="para-2 mb-1 sponDate"> {{AllEvents.DateWithStartTime}} <span *ngIf='AllEvents.DateWithStartTime!="" && AllEvents.Country!=""'>|</span> {{AllEvents.Country}}
                                <span *ngIf='AllEvents.IsSponcered==true'>| <font>Sponsored</font></span>
                            </p>
                            <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="AllEvents.Details"></p>
                            <a target="_blank" class="para-2 para-2-c-2 Read_More" href="past-conferences/{{AllEvents.RouteURL}}">Read More</a>
                        </div>
                    </div>
                  </div>
                </div>
                <a routerLink="/events/past-conferences" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color btnDm">more past conferences</a>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <h3 class="mt-md-0">Webinars</h3>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-5 pr-5 text-uppercase text-center" id="home-tab" data-toggle="tab" href="#webinars-tab1" role="tab" aria-controls="home" aria-selected="true">live webinars</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-3 pr-2 text-uppercase text-center" id="profile-tab" data-toggle="tab" href="#webinars-tab2" role="tab" aria-controls="profile" aria-selected="false">on demand webinars</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="webinars-tab1" role="tabpanel" aria-labelledby="home-tab">
                <div class="row d-md-flex " *ngIf="WebinarLoaded">
                  <div class="fix-min-height-events WrapArt">
                    <div class="col-md-12 pb-5" *ngFor="let webinarNewsData of WebinarDatas">
                      
                        <a target="_blank" href="live-webinars/{{webinarNewsData.RouteURL}}" class="text-decoration-none">
                            <img class="img-fluid pb-2 image-hight-events" [src]="webinarNewsData.ImageUrl" alt="webinar image">
                            <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle" [innerHTML]="webinarNewsData.Name">
                            </h4>
                        </a>
                        <p class="para-2 mb-1 sponDate"> {{webinarNewsData.DateWithStartTime}} 
                            <span *ngIf='webinarNewsData.IsSponcered==true'>| <font>Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="webinarNewsData.Details"></p>
                        <a class="para-2 para-2-c-2 Read_More" target="_blank" href="live-webinars/{{webinarNewsData.RouteURL}} ">Register Now</a>
                        <!-- <a class="para-2 para-2-c-2 " href="events/live-webinars/{{livewebinarNewsData.RouteURL}}/{{livewebinarNewsData.EventID}} ">Register Now</a> -->

                    </div>
                  </div>
                </div>
                <a routerLink="/events/live-webinars" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color liveWeb-btn">more Live webinars</a>
            </div>




            <div class="tab-pane fade" id="webinars-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <div class="tab-pane fade show active pt-3" id="webinars-tab1" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row d-md-flex " *ngIf="OnDemandWebinarLoaded">
                      <div class="fix-min-height-events WrapArt">
                        <div class="col-md-12 pb-5" *ngFor="let onDemandWebinarData of OnDemandWebinarDatas">
                            <!-- <div class="cursor-p" (click)="redirect(onDemandWebinarData.RouteURL,onDemandWebinarData.EventID)"> -->
                                <a target="_blank" [href]="onDemandWebinarData.RouteURL">
                                    <img class="img-fluid pb-2 image-hight-events" [src]="onDemandWebinarData.ImageUrl" alt="webinar image">
                                    <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle" [innerHTML]="onDemandWebinarData.Name">
                                    </h4>
                                </a>
                                <p class="para-2 mb-1" *ngIf='onDemandWebinarData.Company !="" && onDemandWebinarData.Company !=" " && onDemandWebinarData.Company !=null; else NoCompany'> 
                                    {{onDemandWebinarData.Company}} 
                                    <span *ngIf='onDemandWebinarData.IsSponcered==true'>| <font>Sponsored</font></span>
                                </p>
                                <ng-template #NoCompany>
                                    <p class="para-2 mb-0 null-company">
                                        <span *ngIf='onDemandWebinarData.IsSponcered==true'>| <font>Sponsored</font></span>
                                    </p>
                                </ng-template>
                                <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="onDemandWebinarData.Details"></p>
                                <a class="para-2 para-2-c-2 Read_More" target="_blank" [href]="onDemandWebinarData.RouteURL">Watch Now</a>
                            <!-- </div> -->
                        </div>
                    </div>
                  </div>
                </div>
                <a routerLink="/events/on-demand-webinars" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color liveWeb-btn">more on demand webinars</a>
            </div>
        </div>
    </div>
</div>